import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { useState } from "react";

export const MainLayout = () => {
  const theme = useTheme();

  const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Header openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: theme.palette.primary.light,
            borderRadius: 2,
            mr: 2,
            ml: openSidebar ? 4 : 2,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
