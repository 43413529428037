import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import { Dashboard } from "../pages/Dashboard";
import { Login } from "../pages/Login";
import { MainLayout } from "../components/MainLayout";
import { MyData } from "../pages/MyData";
import { Users } from "../pages/Users";
import { UserForm } from "../pages/Users/UserForm";
import { RealEstate } from "../pages/RealEstate";
import { RealEstateForm } from "../pages/RealEstate/RealEstateForm";
import { SiteConfig } from "../pages/SiteConfig";

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/meus-dados" element={<MyData />} />
        <Route path="/usuarios" element={<Users />} />
        <Route path="/usuarios/formulario" element={<UserForm />} />
        <Route path="/imoveis" element={<RealEstate />} />
        <Route path="/imoveis/formulario" element={<RealEstateForm />} />
        <Route path="/configuracoes" element={<SiteConfig />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};
