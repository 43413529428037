import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import realEstateServices from "../../services/realEstate";
import { useQuery } from "react-query";

interface NeighborhoodInputProps {
  defaultNeighborhood?: string;
  setValue?: (name: string, value: any, options?: any) => void;
  state: string;
  city: string;
}

function NeighborhoodInput({
  defaultNeighborhood,
  setValue,
  state,
  city,
}: NeighborhoodInputProps) {
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    defaultNeighborhood || null
  );

  const { data, isLoading } = useQuery(
    ["neighborhoods", state, city], // Use a dynamic key based on state and city
    () => realEstateServices.getAllNeighborhoods(state, city),
    {
      enabled: !!(state && city), // Only enable the query when both state and city are available
    }
  );

  useEffect(() => {
    if (data) {
      setSuggestions(data);
    }
  }, [data]);

  useEffect(() => {
    if (setValue) {
      setValue("neighborhood", selectedOption);
    }
  }, [selectedOption, setValue]);

  const handleInputBlur = () => {
    if (inputValue && !suggestions.includes(inputValue)) {
      setSuggestions([...suggestions, inputValue]);
      setSelectedOption(inputValue);
    }
  };

  return (
    <div>
      <Autocomplete
        disabled={!state || !city}
        id="bairro-input"
        options={suggestions}
        value={selectedOption}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
          setSelectedOption(newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) =>
            option.toLowerCase().includes(params.inputValue.toLowerCase())
          );

          if (
            params.inputValue !== "" &&
            !filtered.includes(params.inputValue)
          ) {
            filtered.push(params.inputValue);
          }

          return filtered;
        }}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              style={{
                margin: "0",
                padding: "0",
                height: "30px",
              }}
              key={option}
            >
              {!suggestions.find((suggestion) => suggestion === option) ? (
                <span
                  style={{
                    paddingRight: "2px",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onClick={handleInputBlur}
                >
                  Clique para adicionar: {option}
                </span>
              ) : (
                <span>{option}</span>
              )}
            </li>
          );
        }}
        //msg quando nao encontrar nada
        noOptionsText="Nenhuma sugestão encontrada, digite para adicionar"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Bairro"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

export default NeighborhoodInput;
