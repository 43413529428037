import api from "./api";

export interface IRealEstate {
  id: string;
  ref: string;
  site: boolean;
  featured: boolean;
  instagram: boolean;
  hub: boolean;
  purpose: string;
  type: string;
  value: number;
  address: string;
  area: number;
  bedrooms: number;
  bathrooms: number;
  condominium: number;
  iptu: number;
  description: string;
  discountedValue: number;
  garages: number;

  subtype: string;
  title: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  images: { id: string; url: string }[];
  user: { name: string };
}

interface IApiCallOptions {
  method: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  data?: any;
}

async function apiCall({ method, url, data }: IApiCallOptions): Promise<any> {
  try {
    const response = await api({ method, url, data });

    // Check for both 200 OK and 204 No Content
    if (response.status !== 200 && response.status !== 204) {
      throw new Error(`Failed to ${method} ${url}`);
    }

    return response.data;
  } catch (error: any) {
    // throw error;
    throw error?.response?.data;
  }
}

export const realEstateServices = {
  getAllRealEstates: (): Promise<IRealEstate[]> =>
    apiCall({ method: "GET", url: "/real-estate" }),
  // Outros métodos do serviço aqui, se necessário
  deleteRealEstate: (id: string): Promise<void> =>
    apiCall({ method: "DELETE", url: `/real-estate/${id}` }),
  updateRealEstate: (id: string, data: any): Promise<void> =>
    apiCall({ method: "PUT", url: `/real-estate/${id}`, data }),
  createRealEstate: (data: any): Promise<void> =>
    apiCall({ method: "POST", url: "/real-estate/", data }),
  getAllNeighborhoods: (state: string, city: string): Promise<any> =>
    apiCall({
      method: "GET",
      url: `/real-estate/neighborhood?state=${state}&city=${city}`,
    }),
};

export default realEstateServices;
