import { ApexOptions } from "apexcharts";

export const TotalRevenueSeries = [
  {
    name: "ultimo mês",
    data: [200, 300, 350, 420, 300],
  },
  {
    name: "este mês",
    data: [300, 350, 420, 300, 310],
  },
  // {
  //   name: "ultimo mês",
  //   data: [],
  // },
  // {
  //   name: "este mês",
  //   data: [],
  // },
];

export const TotalRevenueOptions: ApexOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  colors: ["#475BE8", "#CFC8FF"],
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: false,
      columnWidth: "55%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    show: false,
  },
  stroke: {
    colors: ["transparent"],
    width: 4,
  },
  xaxis: {
    categories: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
  },
  yaxis: {
    title: {
      text: "R$ (Mil)",
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
  },
  tooltip: {
    y: {
      formatter(val: number) {
        return `R$ ${val} mil`;
      },
    },
  },
};
