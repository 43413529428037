import { Divider, Grid, TextField, Typography } from "@mui/material";
import GenericSelect from "../../../components/GenericSelect";
import GenericTextField from "../../../components/GenericTextField";
import { Control, UseFormRegister } from "react-hook-form";

import { PlaceAutocompleteClassic } from "./PlaceAutocompleteClassic";
import { APIProvider } from "@vis.gl/react-google-maps";
import MapPreview from "./MapPreview";
import { useState } from "react";
import NeighborhoodAutocomplete from "../../../components/NeighborhoodAutocomplete";

interface RealEstateFormProps {
  control: Control;
  register: UseFormRegister<any>;
  setValue: (name: string, value: any, options?: any) => void;
  getValues: any;
  price: string;
  discountedPrice: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputDiscountedChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  setAddress: (address: string) => void;
  setLatitude: (latitude: number) => void;
  setLongitude: (longitude: number) => void;
  initialAdress: string | undefined;
}

export const InititalDataTab = ({
  control,
  register,
  price,
  discountedPrice,
  handleInputChange,
  handleInputDiscountedChange,
  setAddress,
  setLatitude,
  getValues,
  setLongitude,
  setValue,
  initialAdress,
}: RealEstateFormProps) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "";

  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [mapKey, setMapKey] = useState<number>(0);
  const [neighborhood, setNeighborhood] = useState<string>("");

  const updateMapKey = () => {
    setMapKey((prevKey) => prevKey + 1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="site"
          label="Publicar no site"
          required
          options={[
            { value: true, label: "Sim" },
            { value: false, label: "Não" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="featured"
          label="Imóvel em destaque"
          required
          options={[
            { value: true, label: "Sim" },
            { value: false, label: "Não" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="instagram"
          label="Publicar no Instagram"
          disabled
          options={[
            { value: true, label: "Sim" },
            { value: false, label: "Não" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="hub"
          label="Hub Imóveis"
          disabled
          options={[
            { value: true, label: "Sim" },
            { value: false, label: "Não" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="purpose"
          label="Finalidade"
          required
          options={[
            { value: "venda", label: "Venda" },
            { value: "aluguel", label: "Aluguel" },
            { value: "temporada", label: "Temporada" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="type"
          label="Tipo de imóvel"
          required
          options={[
            { value: "apartamento", label: "Apartamento" },
            { value: "casa", label: "Casa" },
            { value: "terreno", label: "Terreno" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="subtype"
          label="Subtipo do imóvel"
          required
          options={[
            { value: "padrao", label: "Padrão" },
            { value: "cobertura", label: "Cobertura" },
            { value: "kitnet", label: "Kitnet" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <GenericSelect
          control={control}
          name="condition"
          label="Condição do imóvel"
          required
          options={[
            { value: "novo", label: "Novo" },
            { value: "usado", label: "Usado" },
            { value: "na_planta", label: "Na Planta" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        {/* valor */}
        <TextField
          label="Valor"
          variant="outlined"
          required
          {...register("value")}
          fullWidth
          type="tel"
          value={price}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: price ? true : false,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Valor com desconto"
          {...register("discountedValue")}
          variant="outlined"
          fullWidth
          type="tel"
          value={discountedPrice}
          onChange={handleInputDiscountedChange}
          InputLabelProps={{
            shrink: discountedPrice ? true : false,
          }}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <GenericTextField
          control={control}
          name="title"
          label="Título"
          required
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} md={12}>
        <APIProvider apiKey={API_KEY}>
          <PlaceAutocompleteClassic
            initialAdress={initialAdress}
            onPlaceSelect={(place: any) => {
              const city = place.address_components.find((component: any) =>
                component.types.includes("administrative_area_level_2")
              );
              const neighborhood = place.address_components.find(
                (component: any) =>
                  component.types.includes("sublocality_level_1")
              );
              const state = place.address_components.find((component: any) =>
                component.types.includes("administrative_area_level_1")
              );

              setValue("state", state?.short_name);
              setValue("city", city?.long_name);
              setValue("neighborhood", neighborhood?.long_name);
              setNeighborhood(neighborhood?.long_name);
              setAddress(place.formatted_address);
              setLat(place.geometry.location.lat());
              setLng(place.geometry.location.lng());
              setLatitude(place.geometry.location.lat());
              setLongitude(place.geometry.location.lng());
              updateMapKey(); // Atualiza a chave do mapa
            }}
          />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <NeighborhoodAutocomplete
                key={getValues("city") || neighborhood}
                defaultNeighborhood={getValues("neighborhood") || neighborhood}
                setValue={setValue}
                state={getValues("state") || ""}
                city={getValues("city") || ""}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GenericTextField
                control={control}
                name="condominiumName"
                label="Condomínio"
              />
            </Grid>
          </Grid>

          {lat !== 0 && lng !== 0 && (
            <>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Latitude: {lat}, Longitude: {lng}
              </Typography>
              <MapPreview
                key={mapKey}
                lat={lat}
                lng={lng}
                setLat={setLat}
                setLng={setLng}
                setLatitude={setLatitude}
                setLongitude={setLongitude}

                // reload on props change
              />
            </>
          )}
        </APIProvider>
      </Grid>

      <Grid item xs={12}>
        <GenericTextField
          control={control}
          name="description"
          label="Descrição"
          multiline
          rows={15}
        />
      </Grid>
    </Grid>
  );
};
