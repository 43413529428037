import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  IconButton,
  Chip,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { realEstateServices, IRealEstate } from "../../services/realEstate";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search"; // Import the SearchIcon
import { toast } from "react-toastify";
import { capitalizeFirstLetter, formatToBRL } from "../../utils/functions";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import StraightenIcon from "@mui/icons-material/Straighten";

export const RealEstate = () => {
  const queryClient = useQueryClient();
  const {
    data: realEstates,
    isLoading,
    isError,
  } = useQuery<IRealEstate[]>(
    "realEstates",
    realEstateServices.getAllRealEstates,
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteRealEstateMutation = useMutation(
    (id: string) => realEstateServices.deleteRealEstate(id),
    {
      onSuccess: () => {
        // Invalidate and refetch something when the mutation is successful
        queryClient.invalidateQueries("realEstates");
        toast.success("Imóvel excluído com sucesso!");
      },
      onError: (error) => {
        // Handle error, perhaps using a toast notification, modal, or logging it
        console.error("Failed to delete real estate:", error);
      },
    }
  );

  const handleDelete = (id: string) => {
    deleteRealEstateMutation.mutate(id);
  };

  if (realEstates) {
    console.log(realEstates);
  }

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !realEstates) {
    return (
      <Typography variant="body1" color="error" align="center">
        Erro ao carregar os imóveis
      </Typography>
    );
  }

  return (
    <Box p={3}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Imóveis
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/imoveis/formulario")}
        >
          Adicionar
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ref.</TableCell>
              {/* <TableCell>Foto</TableCell> */}
              <TableCell>Imóvel</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Finalidade</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell
                sx={{ display: { xs: "none", md: "none", lg: "table-cell" } }}
              >
                Garagem
              </TableCell>
              <TableCell
                sx={{ display: { xs: "none", md: "none", lg: "table-cell" } }}
              >
                Quartos
              </TableCell>
              <TableCell
                sx={{ display: { xs: "none", md: "none", lg: "table-cell" } }}
              >
                Banheiros
              </TableCell>
              <TableCell>Área</TableCell>

              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {realEstates.map((realEstate) => (
              <TableRow key={realEstate.id}>
                <TableCell component="th" scope="row">
                  {realEstate?.ref}
                </TableCell>
                {/* <TableCell>
                  <img
                    src={realEstate.images[0].url}
                    alt={`Imagem do imóvel ${realEstate.title}`}
                    style={{ width: "80px", height: "80px" }}
                  />
                </TableCell> */}
                <TableCell>
                  <Box
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="200px"
                  >
                    {realEstate?.title}
                  </Box>
                </TableCell>
                <TableCell>{capitalizeFirstLetter(realEstate?.type)}</TableCell>
                <TableCell>
                  {
                    <Chip
                      color={
                        realEstate?.purpose === "venda"
                          ? "primary"
                          : realEstate?.purpose === "aluguel"
                          ? "secondary"
                          : "success"
                      }
                      label={capitalizeFirstLetter(realEstate?.purpose)}
                    />
                  }
                </TableCell>
                <TableCell>
                  {realEstate?.discountedValue
                    ? formatToBRL(realEstate?.discountedValue)
                    : formatToBRL(realEstate?.value)}
                </TableCell>
                <TableCell
                  sx={{ display: { xs: "none", md: "none", lg: "table-cell" } }}
                >
                  <Box display="flex" alignItems="center">
                    <DriveEtaOutlinedIcon />
                    <Box ml={1}>{realEstate?.garages}</Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{ display: { xs: "none", md: "none", lg: "table-cell" } }}
                >
                  <Box display="flex" alignItems="center">
                    <HotelOutlinedIcon />
                    <Box ml={1}>{realEstate?.bedrooms}</Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{ display: { xs: "none", md: "none", lg: "table-cell" } }}
                >
                  <Box display="flex" alignItems="center">
                    <BathtubOutlinedIcon />
                    <Box ml={1}>{realEstate?.bathrooms}</Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <StraightenIcon />
                    <Box ml={1}>{realEstate?.area} m²</Box>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/imoveis/formulario?id=${realEstate.id}`, {
                          state: { realEstate },
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(realEstate.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RealEstate;
