import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { Control, useController } from "react-hook-form";

interface GenericTextFieldProps
  extends Omit<TextFieldProps, "name" | "variant"> {
  name: string;
  control: Control;
  label: string;
  variant?: "standard" | "filled" | "outlined";
  errorMessage?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

const GenericTextField: React.FC<GenericTextFieldProps> = ({
  name,
  control,
  label,
  variant = "outlined",
  errorMessage,
  required = false,
  minLength,
  maxLength,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required ? "Campo obrigatório" : undefined,
      minLength: minLength
        ? {
            value: minLength,
            message: `Deve ter pelo menos ${minLength} caracteres`,
          }
        : undefined,
      maxLength: maxLength
        ? {
            value: maxLength,
            message: `Deve ter no máximo ${maxLength} caracteres`,
          }
        : undefined,
    },
    defaultValue: "",
  });

  return (
    <TextField
      {...field}
      {...props}
      variant={variant}
      label={label}
      required={required}
      error={Boolean(error)}
      helperText={error?.message || errorMessage}
      fullWidth
    />
  );
};

export default GenericTextField;
