import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  IconButton,
  Chip,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from "react-query";
import brokerServices from "../../services/users";
import { toast } from "react-toastify";
import { IUser } from "../../types/user";

const queryClient = new QueryClient();

export const Users = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);
  const [selectedBrokerId, setSelectedBrokerId] = React.useState<string | null>(
    null
  );

  const {
    data: brokers,
    isLoading,
    isError,
    refetch,
  } = useQuery<IUser[], Error>("brokers", brokerServices.getAllUsers);

  const handleOpenDialog = (id: string) => {
    setSelectedBrokerId(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedBrokerId(null);
    setOpen(false);
  };

  const deleteBroker = useMutation(brokerServices.deleteUser, {
    onSuccess: () => {
      refetch();
      toast.success("Corretor deletado com sucesso!");
    },
    onError: () => {
      toast.error("Erro ao deletar o corretor");
    },
  });

  const handleConfirmDelete = () => {
    if (selectedBrokerId) {
      deleteBroker.mutate(selectedBrokerId);
      handleCloseDialog();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Erro ao carregar os usuários</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Box p={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h3" color="textPrimary" gutterBottom>
            Usuários
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/usuarios/formulario")}
          >
            Adicionar
          </Button>
        </Box>

        <TableContainer
          component={Paper}
          style={{ overflowX: isMobile ? "auto" : "visible" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Telefone / WhatsApp</TableCell>
                <TableCell>CRECI</TableCell>
                <TableCell>Função</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brokers?.map((broker) => (
                <TableRow key={broker.id}>
                  <TableCell
                    align="center"
                    sx={{ "& > *": { margin: "auto" } }}
                  >
                    <Avatar src={broker.photo || "url_do_placeholder"} />
                  </TableCell>
                  <TableCell>{broker.name}</TableCell>
                  <TableCell>{broker.email}</TableCell>
                  <TableCell>{broker.phone}</TableCell>
                  <TableCell>{broker.creci}</TableCell>
                  <TableCell>
                    <Chip
                      label={
                        //admin, broker, customer
                        broker.role === "admin"
                          ? "Administrador"
                          : broker.role === "broker"
                          ? "Corretor"
                          : "Cliente"
                      }
                      color={broker.role === "customer" ? "primary" : "default"}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/usuarios/formulario?id=${broker.id}`)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    {broker.role !== "customer" && (
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDialog(broker.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle fontSize={isMobile ? "small" : "medium"}>
            Confirmar exclusão
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja deletar este corretor?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ pr: 2.5 }}>
            <Button onClick={handleCloseDialog} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="primary"
              variant="contained"
              autoFocus
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </QueryClientProvider>
  );
};
