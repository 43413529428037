// GenericSelect.tsx
import React from "react";
import { Control, useController } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

type OptionType = {
  value: string | boolean;
  label: string;
};

interface GenericSelectProps {
  name: string;
  label: string;
  control: Control;
  options: OptionType[];
  required?: boolean;
  disabled?: boolean;
}

const GenericSelect: React.FC<GenericSelectProps> = ({
  name,
  label,
  control,
  options,
  required = false,
  disabled = false,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: required ? "Campo obrigatório" : false },

    defaultValue: "",
  });

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={Boolean(error)}
      disabled={disabled}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select labelId={`${name}-label`} {...field} label={label}>
        {options.map((option) => (
          <MenuItem key={String(option.value)} value={String(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};

export default GenericSelect;
