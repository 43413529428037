import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import brokerServices from "../../../services/users";
import { IUser } from "../../../types/user";
import { MaskedInputFieldMUI } from "../../../components/MaskedInputFieldMUI";

export const UserForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const isCreatingNewUser = !userId;

  const schema = z
    .object({
      name: z.string().min(3, "O nome deve ter no mínimo 3 caracteres"),
      email: z.string().email("E-mail inválido").min(5),
      phone: z.string().nonempty({ message: "Campo obrigatório" }),
      creci: z.string().min(5, "Número de CRECI inválido"),
      newPassword: isCreatingNewUser
        ? z.string().min(6, "Senha deve ter no mínimo 6 caracteres")
        : z.string().optional(),
      confirmNewPassword: isCreatingNewUser
        ? z.string().min(6, "Confirme a senha com no mínimo 6 caracteres")
        : z.string().optional(),
      role: z.enum(["broker", "admin"], {
        errorMap: (issue, ctx) => ({ message: "Selecione uma função" }),
      }),
      showOnSite: z.boolean().optional(),
    })
    .refine(
      (data) => {
        if (data.newPassword || data.confirmNewPassword) {
          return data.newPassword === data.confirmNewPassword;
        }
        return true;
      },
      {
        message: "As senhas não coincidem",
        path: ["confirmNewPassword"],
      }
    );

  type Schema = z.infer<typeof schema>;

  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const {
    data: broker,
    isLoading,
    isFetched,
  } = useQuery<IUser, Error>(
    ["broker", userId],
    () => brokerServices.getUser(userId as string),
    {
      onSuccess: (data) => {
        setValue("name", data.name || "");
        setValue("email", data.email || "");
        setValue("phone", data.phone || "");
        setValue("creci", data.creci || "");
        setValue("role", data.role as "broker" | "admin");
        setAvatarUrl(data.photo || "");
        setRole(data.role);
        setValue("showOnSite", data.showOnSite || false);
      },
      refetchOnWindowFocus: false,
    }
  );

  const [role, setRole] = useState(broker?.role);

  useEffect(() => {
    setRole(broker?.role);
  }, [broker?.role]);

  useEffect(() => {
    setValue("showOnSite", broker?.showOnSite || false);
  }, [broker?.showOnSite, setValue]);

  const createBrokerMutation = useMutation(
    (newBroker: FormData) => brokerServices.createUser(newBroker),
    {
      onSuccess: () => {
        toast.success("Corretor criado com sucesso");
        navigate("/usuarios");
      },
      onError: (error) => {
        toast.error("Erro ao criar o corretor");
      },
    }
  );

  const updateBrokerMutation = useMutation(
    (updatedBroker: FormData) =>
      brokerServices.updateUser(userId || "", updatedBroker),
    {
      onSuccess: () => {
        toast.success("Corretor atualizado com sucesso");
        navigate("/usuarios");
      },
      onError: (error) => {
        toast.error("Erro ao atualizar o corretor");
      },
    }
  );

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const onSubmit = async (data: Schema) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("creci", data.creci);
    if (data.role) {
      formData.append("role", data.role);
    }
    if (data.showOnSite) {
      formData.append("showOnSite", data.showOnSite.toString());
    }
    if (selectedFile) {
      formData.append("photo", selectedFile);
    }
    if (data.newPassword) {
      formData.append("password", data.newPassword);
    }

    if (userId) {
      await updateBrokerMutation.mutateAsync(formData);
    } else {
      await createBrokerMutation.mutateAsync(formData);
    }
  };

  if (isLoading || !isFetched) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={new QueryClient()}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Paper style={{ padding: 20, margin: 20, borderRadius: 10 }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            {/* Avatar Section */}
            <Box
              position="relative"
              width={200}
              height={250}
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                alignSelf: "center",
              }}
            >
              {avatarUrl && (
                <img
                  src={avatarUrl}
                  alt="Foto do corretor"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              )}
              <input
                accept="image/*"
                hidden
                id="raised-button-file"
                type="file"
                onChange={onFileChange}
              />
              <label htmlFor="raised-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  <PhotoCamera
                    sx={{
                      width: 20,
                      height: 20,
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              </label>
            </Box>

            {/* Input Fields */}

            <Typography variant="h3" color="textPrimary" gutterBottom>
              Dados do corretor
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <TextField
                label="Nome"
                fullWidth
                variant="outlined"
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
                InputLabelProps={{ shrink: !!watch("name") }}
              />

              <TextField
                label="E-mail"
                fullWidth
                variant="outlined"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                InputLabelProps={{ shrink: !!watch("email") }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <TextField
                label="Telefone / WhatsApp"
                fullWidth
                variant="outlined"
                value={getValues("phone")}
                // onChange={(e) => setPhone(e.target.value)}
                {...register("phone")}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                InputLabelProps={{ shrink: watch("phone")?.length > 0 }}
                InputProps={{
                  inputComponent: MaskedInputFieldMUI,
                  inputProps: {
                    mask: "(00) 00000-0000",
                  },
                }}
              />

              <TextField
                label="CRECI"
                fullWidth
                variant="outlined"
                {...register("creci")}
                error={!!errors.creci}
                helperText={errors.creci?.message}
                InputLabelProps={{ shrink: !!watch("creci") }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              {broker?.role !== "customer" && (
                <TextField
                  select
                  label="Função"
                  fullWidth
                  variant="outlined"
                  {...register("role")}
                  value={role} // Use o valor de role aqui
                  onChange={(e) => setRole(e.target.value)}
                  error={!!errors.role}
                  helperText={errors.role?.message}
                >
                  <MenuItem value="broker">Corretor</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </TextField>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      {...register("showOnSite")}
                      onChange={(e) => setValue("showOnSite", e.target.checked)}
                      checked={watch("showOnSite") || false}
                    />
                  }
                  label="Mostrar no site"
                  labelPlacement="start"
                />
              </Box>
            </Box>

            <Typography variant="h4" color="textPrimary" gutterBottom>
              Alterar ou cadastrar uma nova senha
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <TextField
                label="Nova Senha"
                fullWidth
                variant="outlined"
                {...register("newPassword")}
                type={showPassword ? "text" : "password"}
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
              <TextField
                label="Confirmar Nova Senha"
                fullWidth
                variant="outlined"
                {...register("confirmNewPassword")}
                type={showPassword2 ? "text" : "password"}
                error={!!errors.confirmNewPassword}
                helperText={errors.confirmNewPassword?.message}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      {showPassword2 ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                mt: 2,
                gap: 2,
              }}
            >
              {" "}
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate(-1)}
                sx={{ maxWidth: 200, alignSelf: "center" }}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ maxWidth: 200, alignSelf: "center" }}
              >
                {createBrokerMutation.isLoading ||
                updateBrokerMutation.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : userId ? (
                  "Atualizar"
                ) : (
                  "Cadastrar"
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </QueryClientProvider>
  );
};
