import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { AuthContext } from "../components/contexts/AuthContext";
import { useContext } from "react";

export const Routes = () => {
  const { signed, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>; // ou seu componente de carregamento
  }

  return signed ? <PrivateRoutes /> : <PublicRoutes />;
};
