import api from "./api";

import { IIndicators } from "../types/indicators";

interface IApiCallOptions {
  method: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  data?: any;
}

async function apiCall({ method, url, data }: IApiCallOptions): Promise<any> {
  try {
    const response = await api({ method, url, data });
    if (response.status !== 200) {
      throw new Error(`Failed to ${method} ${url}`);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const indicatorServices = {
  getAllIndicators: (): Promise<IIndicators> =>
    apiCall({ method: "GET", url: "/indicators" }),
};

export default indicatorServices;
