import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./assets/scss/style.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes";
import themes from "./themes";
import { AuthProvider } from "./components/contexts/AuthProvider";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export const App = () => {
  const customTheme = themes("default");
  const queryClient = new QueryClient();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <Router>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
