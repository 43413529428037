import { ReactNode, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import authServices, { IAuthResponse } from "../../services/auth";
import userServices from "../../services/users";
import { useNavigate } from "react-router-dom";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IAuthResponse["user"] | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const localToken = localStorage.getItem("@token");
    const localUser = localStorage.getItem("@user");
    if (localToken) {
      setToken(localToken);
    }
    if (localUser) {
      setUser(JSON.parse(localUser));
    }
    setLoading(false); // set loading to false after retrieving data
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await authServices.signIn(email, password);

      if (response.user) {
        setUser(response.user);
        localStorage.setItem("@user", JSON.stringify(response.user));
      }

      if (response.token) {
        setToken(response.token);
        localStorage.setItem("@token", response.token);
      }
    } catch (error) {
      throw error;
    }
  };

  const signOut = async () => {
    // await api.signOut() // se houver uma rota de logout na API
    localStorage.removeItem("@token");
    localStorage.removeItem("@user");
    setUser(null);
    setToken(null);
    navigate("/");
  };

  const updateUser = async (id: string, user: FormData): Promise<any> => {
    try {
      const response = await userServices.updateUser(id, user);
      if (response) {
        console.log(response);
        setUser(response);
        localStorage.setItem("@user", JSON.stringify(response));
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        signIn,
        signOut,
        signed: !!token,
        loading,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
