import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const user = localStorage.getItem("@user");
  const token = localStorage.getItem("@token");

  if (user && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("@user");
      localStorage.removeItem("@token");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default api;
