import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Tabs,
  Tab,
  AppBar,
  Button,
  CircularProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import ImageUpload from "../../../components/ImageUpload";
import { useForm } from "react-hook-form";
import GenericTextField from "../../../components/GenericTextField";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { realEstateServices } from "../../../services/realEstate";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { formatFromBRL, formatToBRL } from "../../../utils/functions";
import { InititalDataTab } from "./InitialDataTab";

interface ImageProps {
  url: string;
  file: File; // Alteração aqui para garantir que seja um File
}

const checkboxData = [
  {
    groupTitle: "Detalhes do imóvel",
    items: [
      { label: "Área de serviço", apiName: "areaDeServico" },
      { label: "Armários no quarto", apiName: "armariosNoQuarto" },
      { label: "Armários na cozinha", apiName: "armariosNaCozinha" },
      { label: "Mobiliado", apiName: "mobiliado" },
      { label: "Ar condicionado", apiName: "arCondicionado" },
      { label: "Churrasqueira", apiName: "churrasqueira" },
      { label: "Varanda", apiName: "varanda" },
      { label: "Academia", apiName: "academiaImovel" },
      { label: "Piscina", apiName: "piscinaImovel" },
      { label: "Quarto de serviço", apiName: "quartoDeServico" },
    ],
  },
  {
    groupTitle: "Detalhes do condomínio",
    items: [
      { label: "Condomínio fechado", apiName: "condominioFechado" },
      { label: "Elevador", apiName: "elevador" },
      { label: "Segurança 24h", apiName: "seguranca24h" },
      { label: "Portaria", apiName: "portaria" },
      { label: "Permitido animais", apiName: "permitidoAnimais" },
      { label: "Academia", apiName: "academiaCondominio" }, // Supondo que a academia do condomínio seja diferente da do imóvel
      { label: "Piscina", apiName: "piscinaCondominio" }, // O mesmo vale para a piscina
      { label: "Salão de festas", apiName: "salaoDeFestas" },
    ],
  },
];

export const RealEstateForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const [files, setFiles] = useState<ImageProps[]>([]);
  const [price, setPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [condominium, setCondominium] = useState("");
  const [iptu, setIptu] = useState("");
  const [address, setAddress] = useState<string>();
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();

  // const [state, setState] = useState<string>();
  // const [city, setCity] = useState<string>();
  const location = useLocation();
  const realEstate = location.state?.realEstate;

  const navigate = useNavigate();

  const mutation = useMutation(
    (data: { id: string; formData: any }) => {
      return realEstateServices.updateRealEstate(data.id, data.formData);
    },
    {
      onSuccess: () => {
        toast.success("Imóvel atualizado com sucesso!");
        navigate("/imoveis");
      },
      onError: (error) => {
        toast.error("Erro ao atualizar imóvel!");
        console.log(error);
      },
    }
  );

  const mutationCreate = useMutation(
    (data: { formData: any }) => {
      return realEstateServices.createRealEstate(data.formData);
    },
    {
      onSuccess: () => {
        toast.success("Imóvel criado com sucesso!");
        navigate("/imoveis");
      },
      onError: (error) => {
        toast.error("Erro ao criar imóvel!");
        console.log(error);
      },
    }
  );

  const { handleSubmit, control, setValue, register, getValues } = useForm({
    defaultValues: {
      ...realEstate,
      site: realEstate?.site ? true : false,
      featured: realEstate?.featured ? true : false,
      instagram: realEstate?.instagram ? true : false,
      hub: realEstate?.hub ? true : false,
    },
  });

  useEffect(() => {
    if (realEstate) {
      setValue("site", realEstate.site ? "true" : "false");
      setValue("featured", realEstate.featured ? "true" : "false");
      setValue("instagram", realEstate.instagram ? "true" : "false");
      setValue("hub", realEstate.hub || false);
      setValue("purpose", realEstate.purpose);
      setValue("type", realEstate.type);
      setValue("subtype", realEstate.subtype);
      setValue("condition", realEstate.condition);
      setValue("cep", realEstate.cep);
      setValue("address", realEstate.address);
      setValue("description", realEstate.description);
      setValue("title", realEstate.title);
      setValue("user", realEstate.user.name);
      setValue("area", realEstate.area);
      setValue("bedrooms", realEstate.bedrooms);
      setValue("bathrooms", realEstate.bathrooms);
      setValue("garages", realEstate.garages);
      setValue("condominium", realEstate.condominium);
      setValue("condominiumName", realEstate.condominium_name);
      setValue("iptu", realEstate.iptu);
      setValue("state", realEstate.state);
      setValue("city", realEstate.city);
      setValue("latitude", realEstate.latitude);
      setValue("longitude", realEstate.longitude);
      setValue("address", realEstate.address);
      setLatitude(realEstate.latitude);
      setLongitude(realEstate.longitude);
      setAddress(realEstate.address);

      realEstate.value ? setPrice(formatToBRL(realEstate.value)) : setPrice("");
      realEstate.discountedValue
        ? setDiscountedPrice(formatToBRL(realEstate.discountedValue))
        : setDiscountedPrice("");
      realEstate.condominium
        ? setCondominium(formatToBRL(realEstate.condominium))
        : setCondominium("");
      realEstate.iptu ? setIptu(formatToBRL(realEstate.iptu)) : setIptu("");

      const preloadedImages = realEstate.images.map((img: any) => ({
        url: img.url,
        file: img.file || null,
      }));

      setFiles(preloadedImages);
      let initialCheckboxValues: Record<string, boolean> = {};

      checkboxData.forEach((group) => {
        group.items.forEach((item) => {
          if (realEstate?.features && realEstate.features[item.apiName]) {
            initialCheckboxValues[item.apiName] = true;
          } else {
            initialCheckboxValues[item.apiName] = false;
          }
        });
      });

      setCheckboxValues(initialCheckboxValues);
    }
  }, [realEstate, setValue]);

  const onSubmit = async (data: any) => {
    const formData = new FormData();

    data.value = formatFromBRL(price);
    data.discountedValue = formatFromBRL(discountedPrice);
    data.condominium = formatFromBRL(condominium);
    data.iptu = formatFromBRL(iptu);
    data.state = getValues("state");
    data.city = getValues("city");
    data.address = address;
    data.latitude = latitude;
    data.longitude = longitude;

    console.log(data);

    for (let key in data) {
      formData.append(key, data[key]);
    }

    for (let i = 0; i < files.length; i++) {
      const image = files[i];
      if (image.file) {
        formData.append(`file${i}`, image.file);
      } else if (image.url) {
        formData.append(`file${i}`, image.url);
      }
    }

    // Adicionar checkbox values ao formData
    for (let key in checkboxValues) {
      formData.append(key, String(checkboxValues[key]));
    }

    if (realEstate && realEstate.id) {
      await mutation.mutateAsync({ id: realEstate.id, formData });
    } else {
      await mutationCreate.mutateAsync({ formData });
    }
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const numericValue =
      parseFloat(event.target.value.replace(/[^\d]/g, "").replace(".", "")) /
      100;
    setPrice(formatToBRL(numericValue));
  }

  function handleInputDiscountedChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const numericValue =
      parseFloat(event.target.value.replace(/[^\d]/g, "").replace(".", "")) /
      100;
    setDiscountedPrice(formatToBRL(numericValue));
  }

  function handleInputCondominiumChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const numericValue =
      parseFloat(event.target.value.replace(/[^\d]/g, "").replace(".", "")) /
      100;
    setCondominium(formatToBRL(numericValue));
  }

  function handleInputIptuChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const numericValue =
      parseFloat(event.target.value.replace(/[^\d]/g, "").replace(".", "")) /
      100;
    setIptu(formatToBRL(numericValue));
  }

  const [checkboxValues, setCheckboxValues] = useState<Record<string, boolean>>(
    {}
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h3" gutterBottom>
          Cadastro de Imóveis
        </Typography>
        <ImageUpload
          onFilesChange={(files) => {
            // Transforme File[] em ImageProps[]
            const images: ImageProps[] = files.map((file) => ({
              url: URL.createObjectURL(file),
              file,
            }));
            setFiles(images); // Aqui setUploadedFiles espera um ImageProps[]
          }}
          initialImages={realEstate?.images.map((img: any) => ({
            file: img.file,
            url: img.url,
          }))}
        />

        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Dados iniciais" />
            <Tab label="Características" />
            <Tab label="Proprietário" disabled />
          </Tabs>
        </AppBar>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {tabValue === 0 && (
              <InititalDataTab
                control={control}
                register={register}
                setValue={setValue}
                initialAdress={address}
                getValues={getValues}
                setAddress={setAddress}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
                price={price}
                discountedPrice={discountedPrice}
                handleInputChange={handleInputChange}
                handleInputDiscountedChange={handleInputDiscountedChange}
              />
            )}
            {tabValue === 1 && (
              <Grid container spacing={2}>
                {/* Área (m²) */}
                <Grid item xs={12} md={3}>
                  <GenericTextField
                    control={control}
                    name="area"
                    label="Área (m²)"
                    required
                  />
                </Grid>
                {/* Número de quartos* */}
                <Grid item xs={12} md={3}>
                  <GenericTextField
                    control={control}
                    name="bedrooms"
                    label="Número de quartos"
                    required
                    type="number"
                  />
                </Grid>
                {/* Número de banheiros */}
                <Grid item xs={12} md={3}>
                  <GenericTextField
                    control={control}
                    name="bathrooms"
                    label="Número de banheiros"
                    required
                    type="number"
                  />
                </Grid>
                {/* Vagas na garagem */}
                <Grid item xs={12} md={3}>
                  <GenericTextField
                    control={control}
                    name="garages"
                    label="Vagas na garagem"
                    required
                    type="number"
                  />
                </Grid>

                {/* Condomínio (R$) */}
                <Grid item xs={12} md={3}>
                  <GenericTextField
                    control={control}
                    name="condominium"
                    label="Condomínio (R$)"
                    onChange={handleInputCondominiumChange}
                    value={condominium}
                    InputLabelProps={{
                      shrink: condominium ? true : false,
                    }}
                  />
                </Grid>
                {/* IPTU (R$) */}
                <Grid item xs={12} md={3}>
                  <GenericTextField
                    control={control}
                    name="iptu"
                    label="IPTU (R$)"
                    onChange={handleInputIptuChange}
                    value={iptu}
                    InputLabelProps={{
                      shrink: iptu ? true : false,
                    }}
                  />
                </Grid>
                {/* bloco vazio */}
                <Grid item xs={12} md={6}></Grid>
                {checkboxData.map((group) => (
                  <Grid item xs={12} md={6} key={group.groupTitle}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{ fontWeight: 600, fontSize: "1rem" }}
                      >
                        {group.groupTitle}
                      </FormLabel>
                      <FormGroup>
                        {group.items.map((item) => (
                          <FormControlLabel
                            key={item.apiName} // use apiName as the key
                            control={
                              <Checkbox
                                checked={checkboxValues[item.apiName] || false}
                                onChange={handleCheckboxChange}
                                name={item.apiName} // use apiName as the checkbox name
                              />
                            }
                            label={item.label} // use 'label' for the label text
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            )}
            {tabValue === 2 && (
              <Grid container spacing={2}>
                {/* Preencha os campos de "Proprietário" aqui */}
              </Grid>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                minWidth: "200px",
              }}
            >
              {mutation.isLoading || mutationCreate.isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Salvar"
              )}
            </Button>
          </form>
        </Paper>
      </Paper>
    </Box>
  );
};

export default RealEstateForm;
