import { Routes, Route } from "react-router-dom";
import { Login } from "../pages/Login";

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {/* se não existir a rota, vai para o login */}
      <Route path="*" element={<Login />} />
    </Routes>
  );
};
