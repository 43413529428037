import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import userServices from "../../services/users";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../components/contexts/AuthContext";
import { toast } from "react-toastify";

const schema = z
  .object({
    name: z.string().min(3, "O nome deve ter no mínimo 3 caracteres"),
    email: z.string().email("E-mail inválido").min(5),
    password: z
      .string()
      .optional()
      .refine(
        (str) => str === undefined || str.trim() === "" || str.length >= 6,
        "A senha deve ter no mínimo 6 caracteres"
      ),
    confirmPassword: z
      .string()
      .optional()
      .refine(
        (str) => str === undefined || str.trim() === "" || str.length >= 6,
        "A senha deve ter no mínimo 6 caracteres"
      ),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "As senhas não coincidem",
    path: ["confirmPassword"],
  });

export const MyData = () => {
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const theme = useTheme();
  const auth = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    // setError,
    // setValue,
    watch,
    reset,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  type Schema = z.infer<typeof schema>;

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const { data } = useQuery(
    ["user", auth.user?.id as string],
    () => userServices.getUser(auth.user?.id as string),
    {
      refetchOnWindowFocus: false,
    }
  );

  const mutation = useMutation(
    (data: FormData) => auth.updateUser(auth.user?.id as string, data),
    {
      onSuccess: () => {
        toast.success("Dados atualizados com sucesso");
      },
      onError: (error) => {
        console.error(error);
        toast.error("Erro ao atualizar dados");
      },
    }
  );

  useEffect(() => {
    if (data) {
      console.log(data);
      reset({
        name: data.name,
        email: data.email,
      });
      setAvatarUrl(data.photo || "");
    }
  }, [data, reset]);

  const onSubmit = (data: Schema) => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("photo", selectedFile);
    }
    formData.append("name", data.name);
    formData.append("email", data.email);
    if (data.password) {
      formData.append("password", data.password);
    }
    if (data.confirmPassword) {
      formData.append("confirmPassword", data.confirmPassword);
    }
    mutation.mutate(formData);
  };

  return (
    <Box>
      <Paper
        style={{
          padding: "20px",
          margin: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            width: "100%",
          }}
        >
          <Box position="relative" width="100px" height="100px">
            <Avatar
              src={avatarUrl}
              alt="Avatar"
              style={{ width: "100%", height: "100%" }}
            />
            <label htmlFor="raised-button-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                <PhotoCamera
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  }}
                />
              </IconButton>
            </label>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={onFileChange}
            />
          </Box>

          <TextField
            label="Nome"
            fullWidth
            variant="outlined"
            required
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            style={{
              maxWidth: "300px",
            }}
            InputLabelProps={{ shrink: !!watch("name") }}
          />

          <TextField
            label="E-mail"
            variant="outlined"
            required
            fullWidth
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            style={{ maxWidth: "300px" }}
            InputLabelProps={{ shrink: !!watch("email") }}
          />

          <TextField
            label="Senha"
            variant="outlined"
            fullWidth
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            style={{ maxWidth: "300px" }}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              ),
            }}
          />

          <TextField
            label="Confirmar senha"
            variant="outlined"
            fullWidth
            {...register("confirmPassword")}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            style={{ maxWidth: "300px" }}
            type={showPassword2 ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword2(!showPassword2)}
                  edge="end"
                >
                  {showPassword2 ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              ),
            }}
          />

          <Button variant="contained" color="primary" type="submit">
            {mutation.isLoading ? <CircularProgress size={24} /> : "Salvar"}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};
