import api from "./api";

import { IUser } from "../types/user";

interface IApiCallOptions {
  method: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  data?: any;
}

async function apiCall({ method, url, data }: IApiCallOptions): Promise<any> {
  try {
    const response = await api({ method, url, data });
    if (response.status !== 200) {
      throw new Error(`Failed to ${method} ${url}`);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const userServices = {
  getAllUsers: (): Promise<IUser[]> =>
    apiCall({ method: "GET", url: "/users" }),
  getUser: (id: string): Promise<IUser> =>
    apiCall({ method: "GET", url: `/users/${id}` }),
  updateUser: (id: string, broker: FormData): Promise<IUser> =>
    apiCall({ method: "PUT", url: `/users/${id}`, data: broker }),
  deleteUser: (id: string): Promise<IUser> =>
    apiCall({ method: "DELETE", url: `/users/${id}` }),
  createUser: (broker: FormData): Promise<IUser> =>
    apiCall({ method: "POST", url: "/users", data: broker }),
};

export default userServices;
