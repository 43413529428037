import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PieChart from "../../components/charts/PieChart";
import TotalRevenue from "../../components/charts/TotalRevenue";
import { useQuery } from "react-query";
import indicatorServices from "../../services/indicators";
import { IIndicators } from "../../types/indicators";

export const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem("@user") || "{}");
  const hasSiteConfig = user.siteConfig;
  const navigate = useNavigate();
  const [indicatorsData, setIndicatorsData] = useState<IIndicators>();

  useEffect(() => {
    if (!hasSiteConfig) {
      navigate("/configuracoes");
    }
  }, [hasSiteConfig, navigate]);

  useQuery<IIndicators>(
    "indicators",
    () => indicatorServices.getAllIndicators(),
    {
      onSuccess: (data) => {
        console.log(data);
        setIndicatorsData(data);
      },
    }
  );

  if (!indicatorsData) {
    return <div>Loading...</div>;
  }

  return (
    <Box display="flex" flexWrap="wrap" gap={4} p={2}>
      <PieChart
        title="Imóveis ativos"
        value={indicatorsData.totalRealEstates}
        series={[
          indicatorsData.totalAvailableRealEstates,
          indicatorsData.totalRealEstates -
            indicatorsData.totalAvailableRealEstates,
        ]}
        colors={["#275be8", "#c4e8ef"]}
      />
      <PieChart
        title="Imóveis para locação"
        value={indicatorsData.totalRentalRealEstates}
        series={[
          indicatorsData.totalRentalRealEstates,
          indicatorsData.totalRealEstates -
            indicatorsData.totalRentalRealEstates,
        ]}
        colors={["#275be8", "#c4e8ef"]}
      />

      <PieChart
        title="Imóveis para venda"
        value={indicatorsData.totalSaleRealEstates}
        series={[
          indicatorsData.totalSaleRealEstates,
          indicatorsData.totalRealEstates - indicatorsData.totalSaleRealEstates,
        ]}
        colors={["#275be8", "#c4e8ef"]}
      />

      <Stack width="100%" direction={{ xs: "column", lg: "row" }} gap={4}>
        <TotalRevenue />
      </Stack>
    </Box>
  );
};
