import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Drawer,
  Stack,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import logo from "../../assets/images/logo.png";

type SidebarProps = {
  openSidebar: boolean;
  setOpenSidebar: (openSidebar: boolean) => void;
};

export const Sidebar = ({ openSidebar, setOpenSidebar }: SidebarProps) => {
  const theme = useTheme();

  // const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const drawerWidth = openSidebar ? 250 : 0;
  const drawerOpen = openSidebar;
  // const drawerToggle = () => setOpenSidebar(!openSidebar);
  const navigate = useNavigate();
  const location = useLocation();

  const drawerToggle = (event: React.SyntheticEvent, reason?: string) => {
    if (!reason || reason === "backdropClick") {
      setOpenSidebar(!openSidebar);
    }
  };

  const avatarStyle = (theme: Theme) => ({
    cursor: "pointer",
    borderRadius: "8px",
    width: "34px",
    height: "34px",
    fontSize: "1.2rem",
    transition: "all .2s ease-in-out",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  });

  return (
    <Box
      component="nav"
      sx={{
        width: drawerWidth,
        display: "flex",
      }}
    >
      <Drawer
        // container={container}
        // variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            // [theme.breakpoints.up("md")]: {
            //   top: "83px",
            // },
          },
        }}
        // ModalProps={{ keepMounted: true }}
        // onBackdropClick={drawerToggle}
      >
        <Box
          component="span"
          sx={{
            display: "flex",
            alignItems: "center",
            p: 2,
            justifyContent: "space-between",
          }}
        >
          <ButtonBase
            sx={{ borderRadius: "12px", overflow: "hidden" }}
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <Avatar variant="rounded" sx={avatarStyle(theme)} color="inherit">
              <MenuOpenIcon />
            </Avatar>
          </ButtonBase>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "150px", cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Box>
        <Divider />
        <Stack sx={{ px: 2, gap: 2, mt: 2 }}>
          <ListItemButton
            selected={location.pathname === "/"}
            sx={{ borderRadius: 4 }}
            onClick={() => {
              navigate("/");
              setOpenSidebar(!openSidebar);
            }}
          >
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          <ListItemButton
            sx={{ borderRadius: 4 }}
            selected={location.pathname === "/imoveis"}
            onClick={() => {
              navigate("/imoveis");
              setOpenSidebar(!openSidebar);
            }}
          >
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Imoveis" />
          </ListItemButton>
          <ListItemButton
            sx={{ borderRadius: 4 }}
            selected={location.pathname === "/clientes"}
          >
            <ListItemIcon>
              <PeopleAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItemButton>
          <ListItemButton
            selected={location.pathname === "/users"}
            sx={{ borderRadius: 4 }}
            onClick={() => {
              navigate("/usuarios");
              setOpenSidebar(!openSidebar);
            }}
          >
            <ListItemIcon>
              <BusinessCenterOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItemButton>
        </Stack>
      </Drawer>
    </Box>
  );
};
