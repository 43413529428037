import { InputBaseComponentProps } from "@mui/material";
import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

export const MaskedInputFieldMUI = forwardRef<
  HTMLInputElement,
  InputBaseComponentProps
>(({ defaultValue, mask, value, ...props }, inputRef) => {
  return (
    <IMaskInput
      {...props}
      inputRef={inputRef}
      mask={mask}
      value={value}
      defaultValue={defaultValue}
    />
  );
});
