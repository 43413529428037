// AuthContext.tsx
import { createContext } from "react";

export interface IUser {
  id: string;
  photo: string;
  hasSiteConfig: boolean;
}

export interface IAuthContext {
  user: IUser | null;
  updateUser: any;
  token: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signed: boolean;
  loading: boolean;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);
