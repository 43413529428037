import api from "./api";

import { ISiteConfig } from "../types/siteConfig";

interface IApiCallOptions {
  method: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  data?: any;
}

async function apiCall({ method, url, data }: IApiCallOptions): Promise<any> {
  try {
    const response = await api({ method, url, data });
    // if (response.status !== 200) {
    //   console.log(response.data);
    // }
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const siteConfigServices = {
  getSiteConfig: (): Promise<ISiteConfig> =>
    apiCall({ method: "GET", url: "/site-config" }),
  //   getSiteConfig: (id: string): Promise<ISiteConfig> =>
  //     apiCall({ method: "GET", url: `/siteConfigs/${id}` }),
  updateSiteConfig: (broker: FormData): Promise<ISiteConfig> =>
    apiCall({ method: "PUT", url: `/site-config`, data: broker }),
  //   deleteSiteConfig: (id: string): Promise<ISiteConfig> =>
  //     apiCall({ method: "DELETE", url: `/siteConfigs/${id}` }),
  createSiteConfig: (broker: FormData): Promise<ISiteConfig> =>
    apiCall({ method: "POST", url: "/site-config", data: broker }),
};
