import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Paper,
  Divider,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { PageWrapper } from "../../components/PageWrapper";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { siteConfigServices } from "../../services/siteConfig";
import { useMutation, useQuery } from "react-query";
import { IWhatsApp } from "../../types/siteConfig";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

import { MaskedInputFieldMUI } from "../../components/MaskedInputFieldMUI";

const WhatsAppEntrySchema = z.object({
  description: z.string().nonempty({ message: "Descrição é obrigatória." }),
  number: z.string().min(10, { message: "Número inválido." }),
});

const schema = z.object({
  siteName: z.string().min(1, "O título do site é obrigatório."),
  description: z.string().min(1, "A descrição do site é obrigatória."),
  // primaryColor: z.string().optional(),
  // secondaryColor: z
  //   .string()
  //   .nonempty({ message: "Cor Secundária é obrigatória." }),
  email: z.string().email({ message: "E-mail inválido." }),
  instagram: z.string().optional(),
  facebook: z.string().optional(),
  youtube: z.string().optional(),
  whatsEntries: z.array(WhatsAppEntrySchema),
  street: z.string().min(1, { message: "Rua é obrigatória." }),
  number: z.string().min(1, { message: "Número é obrigatório." }),
  neighborhood: z.string().min(1, { message: "Bairro é obrigatório." }),
  city: z.string().min(1, { message: "Cidade é obrigatória." }),
  state: z.string().min(1, { message: "Estado é obrigatório." }),
  zipCode: z.string().min(1, { message: "CEP é obrigatório." }),
  highlightedPhrase: z.string().optional(),
});

export const SiteConfig = () => {
  const [whatsEntries, setWhatsEntries] = useState<IWhatsApp[]>([
    { description: "", number: "" },
  ]);
  const [bannerImage, setBannerImage] = useState<string | null>(null);
  const [faviconImage, setFaviconImage] = useState<string | null>(null);
  const [logoImage, setLogoImage] = useState<string | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [faviconFile, setFaviconFile] = useState<File | null>(null);
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [hasSiteConfig, setHasSiteConfig] = useState(false);

  const navigate = useNavigate();

  useQuery(
    "config",
    () => {
      return siteConfigServices.getSiteConfig();
    },
    {
      onSuccess(data) {
        setHasSiteConfig(true);
        setValue("siteName", data?.siteName);
        setValue("description", data?.description);
        // setValue("primaryColor", data?.primaryColor || "#000000");
        // setValue("secondaryColor", data?.secondaryColor || "#000000");
        setValue("email", data?.email);
        setValue("instagram", data?.instagram);
        setValue("facebook", data?.facebook);
        setValue("youtube", data?.youtube);
        setWhatsEntries(data?.WhatsappNumber);
        setBannerImage(data?.banner);
        setFaviconImage(data?.favicon);
        setLogoImage(data?.logo);
        setValue("whatsEntries", data?.WhatsappNumber);

        setValue("street", data?.street);
        setValue("number", data?.number);
        setValue("neighborhood", data?.neighborhood);
        setValue("city", data?.city);
        setValue("state", data?.state);
        setValue("zipCode", data?.zipCode);
        setValue("highlightedPhrase", data?.highlightedPhrase);
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateMutation = useMutation(
    async (data: any) => {
      console.log("hasSiteConfig", hasSiteConfig);
      console.log("data", data);
      const formData = new FormData();
      formData.append("siteName", data.siteName);
      formData.append("description", data.description);
      // formData.append("primaryColor", data.primaryColor);
      // formData.append("secondaryColor", data.secondaryColor);
      formData.append("email", data.email);
      formData.append("instagram", data.instagram);
      formData.append("facebook", data.facebook);
      formData.append("youtube", data.youtube);
      formData.append("street", data.street);
      formData.append("number", data.number);
      formData.append("neighborhood", data.neighborhood);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("zipCode", data.zipCode);
      formData.append("highlightedPhrase", data.highlightedPhrase);

      if (logoFile) {
        formData.append("logo", logoFile);
      } else {
        formData.append("logo", logoImage as string);
      }
      if (faviconFile) {
        formData.append("favicon", faviconFile);
      } else {
        formData.append("favicon", faviconImage as string);
      }
      if (bannerFile) {
        formData.append("banner", bannerFile);
      } else {
        formData.append("banner", bannerImage as string);
      }

      // formData.append("logo", logoImage as string);
      data.whatsEntries.forEach((entry: any, index: number) => {
        formData.append(
          `whatsEntries[${index}][description]`,
          entry.description
        );
        formData.append(`whatsEntries[${index}][number]`, entry.number);
      });
      if (!hasSiteConfig) {
        return siteConfigServices.createSiteConfig(formData);
      }
      return siteConfigServices.updateSiteConfig(formData);
    },
    {
      onSuccess() {
        toast.success("Configurações atualizadas com sucesso.");
        const user = JSON.parse(localStorage.getItem("@user") || "{}");
        user.siteConfig = true;
        localStorage.setItem("@user", JSON.stringify(user));
        navigate("/");
      },
      onError(error) {
        toast.error("Erro ao atualizar as configurações.");
      },
    }
  );

  useEffect(() => {
    // Este é o código de limpeza
    return () => {
      if (logoImage) {
        URL.revokeObjectURL(logoImage);
      }
    };
  }, [logoImage]);

  type Schema = z.infer<typeof schema>;
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setError,
    watch,
    setValue,
    getValues,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<string | null>>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setImage(objectURL);
      setFile(file);
    }
  };

  const onSubmit = (data: any) => {
    //create or update
    updateMutation.mutate(data);
  };

  return (
    <PageWrapper title="Configurações do Site">
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Titulo do Site"
                variant="outlined"
                fullWidth
                {...register("siteName")}
                error={!!errors.siteName}
                helperText={errors.siteName?.message}
                InputLabelProps={{ shrink: watch("siteName")?.length > 0 }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                label="Descrição"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                {...register("description")}
                error={!!errors.description}
                helperText={errors.description?.message}
                InputLabelProps={{ shrink: !!watch("description")?.length }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Frase em destaque"
                variant="outlined"
                rows={4}
                fullWidth
                {...register("highlightedPhrase")}
                error={!!errors.highlightedPhrase}
                helperText={errors.highlightedPhrase?.message}
                InputLabelProps={{
                  shrink: !!watch("highlightedPhrase")?.length,
                }}
              />
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" gap={2}>
                <TextField
                  label="Cor Primária"
                  type="color"
                  variant="outlined"
                  defaultValue={"#0000000"}
                  {...register("primaryColor")}
                  error={!!errors.primaryColor}
                  helperText={errors.primaryColor?.message}
                  inputProps={{ style: { height: "50px", width: "80px" } }}
                />
                <TextField
                
                  label="Cor Secundária"
                  type="color"
                  variant="outlined"
                  defaultValue={"#000000"}
                  {...register("secondaryColor")}
                  error={!!errors.secondaryColor}
                  helperText={errors.secondaryColor?.message}
                  inputProps={{ style: { height: "50px", width: "80px" } }}
                />
              </Box>
            </Grid> */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography variant="caption"> Números de WhatsApp:</Typography>
              <Divider sx={{ width: "100%", ml: 3 }} />
            </Box>

            <Grid item xs={12} md={6}>
              {whatsEntries.map((entry, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    label="Descrição"
                    variant="outlined"
                    value={entry.description}
                    {...register(`whatsEntries.${index}.description`)}
                    error={!!errors.whatsEntries?.[index]?.description}
                    helperText={
                      errors.whatsEntries?.[index]?.description?.message
                    }
                    onChange={(e) => {
                      const newEntries = [...whatsEntries];
                      newEntries[index].description = e.target.value;
                      setWhatsEntries(newEntries);
                    }}
                    style={{ marginRight: 15, flex: 1 }}
                  />
                  <TextField
                    label="Número"
                    variant="outlined"
                    value={entry.number}
                    {...register(`whatsEntries.${index}.number`)}
                    error={!!errors.whatsEntries?.[index]?.number}
                    helperText={errors.whatsEntries?.[index]?.number?.message}
                    onChange={(e) => {
                      const newEntries = [...whatsEntries];
                      newEntries[index].number = e.target.value;
                      setWhatsEntries(newEntries);
                    }}
                    style={{ flex: 1 }}
                    InputProps={{
                      inputComponent: MaskedInputFieldMUI,
                      inputProps: {
                        mask: "(00) 00000-0000",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      width: "100px",
                    }}
                  >
                    {whatsEntries.length > 1 && (
                      <IconButton
                        onClick={() => {
                          const newEntries = [...whatsEntries];
                          newEntries.splice(index, 1);
                          setWhatsEntries(newEntries);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    )}
                    {index === whatsEntries.length - 1 && (
                      <IconButton
                        onClick={() => {
                          setWhatsEntries([
                            ...whatsEntries,
                            { description: "", number: "" },
                          ]);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))}
            </Grid>
            <Divider sx={{ width: "100%", mt: 2, ml: 3 }} />

            <Grid item xs={12} md={4}>
              <TextField
                label="E-mail formulário de contato "
                variant="outlined"
                fullWidth
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                InputLabelProps={{ shrink: watch("email")?.length > 0 }}
              />
            </Grid>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography variant="caption">Endereço</Typography>
              <Divider sx={{ width: "100%", ml: 3 }} />
            </Box>

            <Grid item xs={12} md={4}>
              <TextField
                label="Rua"
                variant="outlined"
                fullWidth
                {...register("street")}
                error={!!errors.street}
                helperText={errors.street?.message}
                InputLabelProps={{ shrink: watch("street")?.length > 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Número"
                variant="outlined"
                fullWidth
                {...register("number")}
                error={!!errors.number}
                helperText={errors.number?.message}
                InputLabelProps={{ shrink: watch("number")?.length > 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Bairro"
                variant="outlined"
                fullWidth
                {...register("neighborhood")}
                error={!!errors.neighborhood}
                helperText={errors.neighborhood?.message}
                InputLabelProps={{ shrink: watch("neighborhood")?.length > 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Cidade"
                variant="outlined"
                fullWidth
                {...register("city")}
                error={!!errors.city}
                helperText={errors.city?.message}
                InputLabelProps={{ shrink: watch("city")?.length > 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Estado"
                variant="outlined"
                fullWidth
                {...register("state")}
                error={!!errors.state}
                helperText={errors.state?.message}
                InputLabelProps={{ shrink: watch("state")?.length > 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="CEP"
                variant="outlined"
                fullWidth
                value={getValues("zipCode")}
                {...register("zipCode")}
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message}
                InputLabelProps={{ shrink: watch("zipCode")?.length > 0 }}
                InputProps={{
                  inputComponent: MaskedInputFieldMUI,
                  inputProps: {
                    mask: "00000-000",
                  },
                }}
              />
            </Grid>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography variant="caption">
                Redes Sociais (URLs completas)
              </Typography>
              <Divider sx={{ width: "100%", ml: 3 }} />
            </Box>

            <Grid item xs={12} md={4}>
              <TextField
                label="Instagram"
                variant="outlined"
                fullWidth
                {...register("instagram")}
                error={!!errors.instagram}
                helperText={errors.instagram?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Facebook"
                variant="outlined"
                fullWidth
                {...register("facebook")}
                error={!!errors.facebook}
                helperText={errors.facebook?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="YouTube"
                variant="outlined"
                fullWidth
                {...register("youtube")}
                error={!!errors.youtube}
                helperText={errors.youtube?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <YouTubeIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography variant="caption">
                Imagens (Banner/Favicon/Logo)
              </Typography>
              <Divider sx={{ width: "100%", ml: 3 }} />
            </Box>

            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <input
                accept="image/*"
                id="banner-upload"
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleImageChange(e, setBannerImage, setBannerFile)
                }
              />
              <label htmlFor="banner-upload">
                <Button variant="outlined" component="span">
                  Escolher Banner
                </Button>
              </label>
              <Paper
                elevation={2}
                style={{ width: "200px", height: "120px", marginTop: "10px" }}
              >
                {bannerImage && (
                  <img
                    src={bannerImage}
                    alt="Banner Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                  />
                )}
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <input
                accept="image/*"
                id="favicon-upload"
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleImageChange(e, setFaviconImage, setFaviconFile)
                }
              />
              <label htmlFor="favicon-upload">
                <Button variant="outlined" component="span">
                  Escolher Favicon
                </Button>
              </label>
              <Paper
                elevation={2}
                style={{ width: "80px", height: "80px", marginTop: "10px" }}
              >
                {faviconImage && (
                  <img
                    src={faviconImage}
                    alt="Favicon Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                  />
                )}
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <input
                accept="image/*"
                id="logo-upload"
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleImageChange(e, setLogoImage, setLogoFile)
                }
              />
              <label htmlFor="logo-upload">
                <Button variant="outlined" component="span">
                  Escolher Logo
                </Button>
              </label>
              <Paper
                elevation={2}
                style={{ width: "200px", height: "100px", marginTop: "10px" }}
              >
                {logoImage && (
                  <img
                    src={logoImage}
                    alt="Logo Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                  />
                )}
              </Paper>
            </Grid>

            <Divider sx={{ width: "100%", mt: 2, ml: 3 }} />

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ display: "block", margin: "0 auto", width: "200px" }}
              >
                {updateMutation.isLoading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Salvar Configurações"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </PageWrapper>
  );
};
