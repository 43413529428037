import axios from "axios";

const api = process.env.REACT_APP_API_URL;

export interface IAuthResponse {
  user: any; // atualize com a estrutura real do usuário
  token: string;
  // outros campos da resposta de autenticação...
}

export interface IAuthServices {
  signIn: (email: string, password: string) => Promise<IAuthResponse>;
}

export const authServices = () => ({
  signIn: async (email: string, password: string): Promise<IAuthResponse> => {
    try {
      const response = await axios.post(`${api}/auth/`, {
        email,
        password,
      });
      if (response.status !== 200) {
        throw new Error("Failed to authenticate");
      }
      return response.data;
    } catch (error) {
      // lançando o erro para ser manipulado pelo componente que chama o signIn
      throw error;
    }
  },
});

export default authServices() as IAuthServices;
