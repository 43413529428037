import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  Chip,
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useEffect, useRef, useState } from "react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate } from "react-router-dom";

export const ProfileSection = () => {
  const theme = useTheme();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut();
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <Chip
          ref={anchorRef} // Move ref to Chip
          sx={{
            position: "relative",
            height: "48px",
            alignItems: "center",
            borderRadius: "27px",
            transition: "all .2s ease-in-out",
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light,
              "& svg": {
                stroke: theme.palette.primary.light,
              },
            },
            "& .MuiChip-label": {
              lineHeight: 0,
            },
          }}
          icon={
            <Avatar
              src={auth.user?.photo}
              sx={{
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
              }}
              color="inherit"
            />
          }
          label={<SettingsOutlinedIcon />}
          variant="outlined"
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
        <Box
          sx={{
            zIndex: 1,
            position: "absolute",
            marginTop: 4,
            right: 0,
            mr: 4,
            display: open ? "block" : "none",
          }}
        >
          <Card
            sx={{
              p: 2,
              minWidth: "300px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              <Box fontWeight="bold">John Doe</Box>
            </Typography>
            <ListItemButton
              sx={{ borderRadius: 4 }}
              onClick={() => {
                navigate("/meus-dados");
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <PersonOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body2">Meus dados</Typography>}
              />
            </ListItemButton>
            <ListItemButton
              sx={{ borderRadius: 4 }}
              onClick={() => {
                navigate("/configuracoes");
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body2">Configurações</Typography>}
              />
            </ListItemButton>

            <ListItemButton sx={{ borderRadius: 4 }} onClick={handleLogout}>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body2">Sair</Typography>}
              />
            </ListItemButton>
          </Card>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
