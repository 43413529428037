import { Box, Paper, Typography } from "@mui/material";

interface PageWrapperProps {
  children: React.ReactNode;
  title: string;
}

export const PageWrapper = ({ children, title }: PageWrapperProps) => {
  return (
    <Box p={3}>
      <Typography variant="h3" gutterBottom mb={4}>
        {title}
      </Typography>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};
