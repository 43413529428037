import { Button, IconButton, Paper, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/CloudUpload";

interface ImageProps {
  url: string;
  file?: File;
  id?: string; // Optional ID, which could be useful for server-stored images.
}

interface ImageUploadProps {
  onFilesChange: (files: File[]) => void;
  initialImages?: ImageProps[]; // Aqui está a nova prop
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onFilesChange,
  initialImages = [],
}) => {
  const [images, setImages] = useState<ImageProps[]>(initialImages);
  const [dragging, setDragging] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (files: FileList | null) => {
    if (files) {
      const filesArray: ImageProps[] = Array.from(files).map((file) => ({
        url: URL.createObjectURL(file),
        file,
      }));

      // Combinando imagens
      const combinedImages = [...images, ...filesArray];

      setImages(combinedImages);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      // Atualizar o callback
      const allImagesFiles: File[] = combinedImages.map((img) =>
        img.file ? img.file : new File([], img.url)
      );

      onFilesChange(allImagesFiles);
    }
  };

  const removeImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    // Atualizar o callback
    const files: File[] = newImages.map((img) =>
      img.file ? img.file : new File([], img.url)
    );

    onFilesChange(files);
  };

  const handleDragEvents = (
    e: React.DragEvent<HTMLDivElement>,
    entering: boolean
  ) => {
    e.preventDefault();
    setDragging(entering);
  };

  return (
    <div
      onDragOver={(e) => handleDragEvents(e, true)}
      onDragLeave={(e) => handleDragEvents(e, false)}
      onDrop={(e) => {
        handleDragEvents(e, false);
        handleImageChange(e.dataTransfer.files);
      }}
      style={{
        border: dragging ? "3px dashed #3f51b5" : "3px dashed #e0e0e0",
        borderRadius: "10px",
        padding: "20px",
        textAlign: "center",
        margin: "20px 0",
        width: "100%",
      }}
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={(e) => handleImageChange(e.target.files)}
        ref={fileInputRef}
      />
      <label htmlFor="raised-button-file">
        <Button
          startIcon={<UploadIcon />}
          variant="contained"
          component="span"
          color="primary"
        >
          Carregar Imagens
        </Button>
      </label>

      {dragging && (
        <Typography variant="h6" style={{ marginTop: 10 }}>
          Solte as imagens aqui
        </Typography>
      )}

      <div
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "16px",
          marginTop: "16px",
          maxHeight: "420px",
          maxWidth: "92vw",
        }}
      >
        {images.map((img, index) => (
          <Paper
            key={index}
            elevation={3}
            style={{
              display: "flex", // Adicione esta linha
              justifyContent: "center", // Adicione esta linha
              alignItems: "center", // Adicione esta linha
              position: "relative",
              flexShrink: 0,
              width: "300px",
              height: "300px", // Adicione esta linha, para dar uma altura fixa
              borderRadius: "10px",
              overflow: "hidden",
              marginBottom: "16px",
            }}
          >
            <img
              src={img.url}
              alt=""
              onError={(e) => {
                e.currentTarget.onerror = null; // Para evitar chamadas repetidas
                console.error("Erro ao carregar a imagem", e);
              }}
              style={{
                maxHeight: "100%", // Modifique esta linha
                maxWidth: "100%", // Adicione esta linha
                objectFit: "contain",
              }}
            />

            <div style={{ position: "absolute", top: 5, right: 5 }}>
              <IconButton
                onClick={() => removeImage(index)}
                color="secondary"
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Paper>
        ))}
      </div>
    </div>
  );
};

export default ImageUpload;
