import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  InputAdornment,
  TextField,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
// import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { ProfileSection } from "./ProfileSection";
import { useNavigate } from "react-router-dom";

type HeaderProps = {
  openSidebar: boolean;
  setOpenSidebar: (openSidebar: boolean) => void;
};

export const Header = ({ openSidebar, setOpenSidebar }: HeaderProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
      <Box
        component="span"
        sx={{
          // display: { xs: "none", md: "flex" },
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          gap: 4,
        }}
      >
        <ButtonBase
          sx={{ borderRadius: "12px", overflow: "hidden" }}
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          <Avatar
            variant="rounded"
            sx={{
              cursor: "pointer",
              borderRadius: "8px",
              width: "34px",
              height: "34px",
              fontSize: "1.2rem",
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            color="inherit"
          >
            <MenuIcon />
          </Avatar>
        </ButtonBase>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "150px", cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Box>

        <TextField
          //   label="Pesquisar"
          variant="outlined"
          placeholder="Pesquisar"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: theme.palette.grey[500] }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Avatar
                  variant="rounded"
                  sx={{
                    cursor: "pointer",
                    borderRadius: "8px",
                    width: "34px",
                    height: "34px",
                    fontSize: "1.2rem",
                    transition: "all .2s ease-in-out",
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    "&:hover": {
                      background: theme.palette.secondary.dark,
                      color: theme.palette.secondary.light,
                    },
                  }}
                  color="inherit"
                >
                  <TuneIcon />
                </Avatar>
              </InputAdornment>
            ),
          }}
          sx={{
            display: { xs: "none", md: "flex" },
            width: 434,
            marginLeft: 2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Avatar
          variant="rounded"
          sx={{
            cursor: "pointer",
            borderRadius: "8px",
            width: "34px",
            height: "34px",
            fontSize: "1.2rem",
            transition: "all .2s ease-in-out",
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            "&:hover": {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
          }}
          color="inherit"
        >
          <NotificationsNoneOutlinedIcon />
        </Avatar>
        <ProfileSection />
      </Box>
    </Box>
  );
};
