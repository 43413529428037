import React, { useRef, useEffect, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { Box, TextField } from "@mui/material";

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  initialAdress: string | undefined;
}

export const PlaceAutocompleteClassic = ({
  onPlaceSelect,
  initialAdress,
}: Props) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {};

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  useEffect(() => {
    if (initialAdress && inputRef.current) {
      inputRef.current.value = initialAdress;
    }
  }, [initialAdress]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <TextField fullWidth inputRef={inputRef} />
    </Box>
  );
};
