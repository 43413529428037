export function formatToBRL(value: number): string {
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function formatFromBRL(value: string): number {
  const numericValue = parseFloat(
    value.replace("R$", "").replace(/\./g, "").replace(",", ".")
  );
  return numericValue;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
