import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useContext, useState } from "react";
import { AuthContext } from "../../components/contexts/AuthContext";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const schema = z.object({
  email: z.string().email("Email inválido"),
  password: z.string().min(6, "A senha deve ter no mínimo 6 caracteres"),
});

type Inputs = z.infer<typeof schema>;

export const Login = () => {
  type Schema = z.infer<typeof schema>;

  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setError,
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
  });
  const auth = useContext(AuthContext);
  const { mutate, isLoading } = useMutation(async (data: Schema) => {
    try {
      await auth.signIn(data.email, data.password);
    } catch (error) {
      console.error(error);
      toast.error("E-mail ou senha inválidos");
      throw error;
    }
  });

  function onSubmit(data: Schema) {
    mutate(data);
  }

  return (
    <Box
      id="wrapper"
      sx={{
        backgroundColor: theme.palette.primary.light,
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        id="login"
        sx={{
          width: "100%",
          maxWidth: "400px",
          backgroundColor: "#ffffffff",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          id="logo"
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <img src={logo} alt="Logo" style={{ width: "50%" }} />
        </Box>
        <Typography color="primary" gutterBottom variant="h4">
          Informe seus dados
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Email"
              variant="outlined"
              required
              fullWidth
              {...register("email", { required: true })}
              error={!!errors.email}
              helperText={errors.email ? "Email inválido" : ""}
            />
            <TextField
              label="Senha"
              variant="outlined"
              required
              fullWidth
              {...register("password", { required: true })}
              error={!!errors.password}
              helperText={errors.password ? "Senha inválida" : ""}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: showPassword ? (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <VisibilityOffOutlinedIcon />
                  </IconButton>
                ),
              }}
            />
            <Button variant="contained" fullWidth color="primary" type="submit">
              {isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                "Login"
              )}
            </Button>
          </Box>
        </form>
        <Typography
          component={Link}
          to="/#"
          variant="subtitle1"
          sx={{ textDecoration: "none" }}
        >
          Esqueceu a senha?
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
