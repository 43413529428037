import React from "react";
import { Map, Marker } from "@vis.gl/react-google-maps";
import { Box } from "@mui/material";

interface MapPreviewProps {
  lat: number;
  lng: number;
  setLat: (lat: number) => void;
  setLng: (lng: number) => void;
  setLatitude: (lat: number) => void;
  setLongitude: (lng: number) => void;
}

export default function MapPreview({
  lat,
  lng,
  setLat,
  setLng,
  setLatitude,
  setLongitude,
}: MapPreviewProps) {
  return (
    <Box>
      <Map
        style={{ width: "100%", height: "400px" }}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={18}
        // gestureHandling={"greedy"}
        disableDefaultUI={true}
        onClick={(e) => {
          console.log(e);
          const lat = e.detail.latLng?.lat || 0;
          const lng = e.detail.latLng?.lng || 0;
          setLat(lat);
          setLng(lng);
          setLatitude(lat);
          setLongitude(lng);
        }}
        styles={[
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ]}
      >
        {/* simple marker */}
        <Marker position={{ lat: lat, lng: lng }} clickable={false} />
      </Map>
    </Box>
  );
}
